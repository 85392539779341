import React, { useEffect, useState } from "react";
import "./blog.scss";
import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import SideBar from "../../components/sidebar/SideBar";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import noData from "../../../assets/images/home/no-data.png";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [visiblePosts, setVisiblePosts] = useState(6);
  const { search } = useLocation();

  useEffect(() => {
    const fetchPots = async () => {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/posts" + search
      );
      setPosts(res.data);
    };
    fetchPots();
  }, [searchTerm]);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;

    if (windowHeight + scrollTop === documentHeight) {
      // When scrolled to the bottom, increase visiblePosts by 2
      setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 2);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Filter posts based on searchTerm
  const filteredPosts = posts
    .filter((post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(0, visiblePosts);

  return (
    <>
      <Header />
      <div className="container">
        <div className="blog">
          {filteredPosts.length === 0 && (
            <div className="posts noData">
              <img src={noData} alt="" />
            </div>
          )}
          {filteredPosts.length > 0 && <Posts posts={filteredPosts} />}
          <div className="left__side">
            <SideBar />
            <div className="blog__search">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search..."
                className="blog__searchInput"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
