import React, { useState } from "react";
import "./forgotpass.scss";
import axios from "axios";

const ForgotPass = () => {
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [err, setError] = useState(null);
  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.post(process.env.REACT_APP_API_URL + "/auth/reset-password", {
        email,
      });
      setIsSuccess(true);
    } catch (error) {
      console.error("Error sending new password:", error.response.data.message);
      setError(error.response.data.message);
    }
  };

  return isSuccess ? (
    <div className="forgot-pass">
      <img
        src="https://fma.dev.techland.link/assets/images/background/success.png"
        alt="mail success"
      />
      <span>The new password has been sent to the registered email.</span>
    </div>
  ) : (
    <div className="forgot-pass">
      <span className="loginTitle">
        Forgot
        <br />
        Password
      </span>
      <form action="" className="loginForm">
        <label>Email</label>
        <input
          name="email"
          type="email"
          className="loginInput"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email ..."
        />
        {err && <p className="loginMessage">{err}</p>}
        <button className="loginButton" onClick={resetPassword}>
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ForgotPass;
