import React, { useContext, useState } from "react";
import "./createmail.scss";
import axios from "axios";
import { AuthContext } from "../../context/authContext/authContext";
import DefaultImg from "../../../assets/images/icon/no-img.png";

const CreateEmail = ({ onClose, onPostCreated, id }) => {
  const [mail, setMail] = useState("");

  const { currentUser, dispatch } = useContext(AuthContext);

  const handleSave = async (e) => {
    e.preventDefault();
    const body = {
      contactMail: mail,
    };
    if (id === null) {
      try {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/emails",
          body,
          {
            headers: { token: "Bearer " + currentUser.accessToken },
          }
        );
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const res = await axios.put(
          process.env.REACT_APP_API_URL + "/emails/" + id,
          body,
          {
            headers: { token: "Bearer " + currentUser.accessToken },
          }
        );
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }

    // Đóng popup
    onClose();

    // Thông báo rằng bài viết đã được tạo
    onPostCreated();
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>Update Contact Email</h2>
        <div className="form-group">
          <label htmlFor="phone">Mail</label>
          <input
            id="mail"
            type="text"
            value={mail}
            autoFocus={true}
            onChange={(e) => setMail(e.target.value)}
          />
        </div>

        <button className="btn__add" onClick={handleSave}>
          Save
        </button>
        <button className="btn__cancel" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateEmail;
